import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  days,
  deleteIcon,
  editIcon,
  pdfIcon
} from "../../../../assets/svgIcons";
import AddLecture from "../../../../components/Lectures/add";
import DeleteLecture from "../../../../components/Lectures/delete";
import EditLecture from "../../../../components/Lectures/edit";
import ShowHideLectures from "../../../../components/Lectures/showHide";
import CustomTable from "../../../../components/table";
import { secondUrl } from "../../../../utils/baseUrl";
import Modal from "../../../../components/modal";
import Toast from "../../../../components/toast";
import toast from "react-hot-toast";
import { FaTrash } from "react-icons/fa";
import Loader from "../../../../components/loader";
import axios from "axios";
// import "./style.css";

function NotesLectures() {
  const userPermissions = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin"))?.permissions : null;
  const [spliitedUserPermission , setSplittedUserPermissions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowData , setRowData] = useState({});
  const [openShowHideModal, setOpenShowHideModal] = useState(false);
  const [initialLectureData, setInitialLectureData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [editModal , setEditModal] = useState(false);
  const columns = [
    {
      key: "name",
      title: "Lecture Name",
      dataIndex: "lec_title",
      search: true,
    },
    {
      key: "lec_cover_link",
      title: "Image",
      dataIndex: "lec_cover_link",
      render: (row) => {
        console.log(row);
        return (
          <img
            src={row}
            style={{ width: "100px" }}
            alt=""
            className=""
          />
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <div className="actions-btns">
          
         {spliitedUserPermission?.includes("13") &&  <div
            className="delete-btn c-pointer text-danger"
            onClick={() => setOpenDeleteModal(row)}
          >
            <div className="tooltip">Delete</div>
            {deleteIcon}
          </div>}

          {/* <div
            className="delete-btn c-pointer text-success"
            onClick={() => {
              setRowData(row)
              setEditModal(true)
            }}
          >
            <div className="tooltip">Edit</div> 
            {editIcon}
          </div> */}
          {/* 
          <div
            onClick={() => setOpenShowHideModal(row)}
            className={
              row?.hidden
                ? "showhide-btn c-pointer text-success"
                : "showhide-btn c-pointer text-danger"
            }
          >
            <div className="tooltip">{row?.hidden ? "Show" : "Hide"}</div>
            {row?.hidden ? closedEye : openedEye}
          </div>
          <div
            className="open-btn c-pointer text-primary"
            onClick={() => setOpenEditModal(row)}
          >
            <div className="tooltip">Edit</div>
            {editIcon}
          </div> */}
          {spliitedUserPermission?.includes("40") && <div
            className="open-btn c-pointer text-primary"
            onClick={() => navigate(`${row?.lec_id}/general`)}
          >
            <div className="tooltip">PDF</div>
            {pdfIcon}
          </div>}
        </div>
      ),
    },
  ];
  const { subject, level_id } = useParams();
  const getAllMyVediosFor = async () => {
    setIsPageLoading(true);

    let data_to_send = {
      level_id:level_id
      
    };


    try {
      const response = await fetch("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/select_lectures_by_level.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data_to_send),
      });

      const resData = await response.json();
      console.log(resData);

      if (Array.isArray(resData) && resData.length !== 0) {
        const allData = resData.map((item) => ({
          ...item,
          deleteLoading: false,
          editLoading: false, 
        }));
console.log(allData)
        setInitialLectureData(allData);
      } else {
        setInitialLectureData([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    getAllMyVediosFor();
  }, []); 

  useEffect(() =>{
    const spliitedPermissions = userPermissions.split("//CAMP//");
    setSplittedUserPermissions(spliitedPermissions);
  } , [userPermissions ])

  const handleImageChange = (e) => {
    setRowData({ ...rowData, image: e.target.files[0] });
  };

  
  const removeImage = () => {
    if (rowData.lec_cover_link) {
      
      axios
        .delete(`/api/lectures/${rowData?.lec_id}/image`)
        .then(() => {
          setRowData({ ...rowData, image: null });
        })
        .catch((err) => {
          console.error("Failed to delete image:", err);
        });
    } else {
      setRowData({ ...rowData, image: null });
    }
  };

  return (
    <div className="lectures">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Lectures</h1>
        {spliitedUserPermission?.includes("12") && <button
          className="btn btn-success"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Add Lecture
        </button>}
      </div>

      <Modal
      close={() => setEditModal(false)}
      footer={false}
      title={"Edit Lecture"}
      visible={editModal}
    >
      <form onSubmit={""} className="animated-form">
        <div className="form-group">
          <label htmlFor="lectureName" className="form-label">
            Lecture Name
          </label>
          <input
            type="text"
            id="lectureName"
            placeholder="Enter Lecture Name"
            value={rowData.lec_title}
            onChange={(e) => setRowData({ ...rowData, lec_title: e.target.value })}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lectureImage" className="form-label">
            Lecture Image (Optional)
          </label>
          <input
            type="file"
            id="lectureImage"
            // onChange={handleImageChange}
            className="form-input"
          />
          {/* {openModal?.image && !formData?.image && (
            <div className="image-preview">
              <img src={openModal.image} alt="Lecture" />
              <button type="button" onClick={removeImage} className="remove-image-btn">
                <FaTrash />
              </button>
            </div>
          )} */}
          {rowData.lec_cover_link && (
            <div className="image-preview">
              <img src={URL.createObjectURL(rowData.lec_cover_link)} alt="Lecture Preview" />
              <button type="button" onClick={removeImage} className="remove-image-btn">
                <FaTrash />
              </button>
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="lectureDescription" className="form-label">
            Description
          </label>
          <textarea
            id="lectureDescription"
            placeholder="Enter Lecture Description"
            value={rowData.lec_descriprion}
            onChange={(e) => setRowData({ ...rowData, lec_descriprion: e.target.value })}
            className="form-input"
            rows="4"
          />
        </div>
        <div className="form-footer">
          {rowData?.loading ? (
            <Loader/>
          ) : (
            <button type="submit" className="form-submit-btn btn btn-primary">
              Save
            </button>
          )}
        </div>
        {/* {toast && (
          <Toast message={"Please fill out all required fields"} type={"error"} onClose={() => setToast(false)} />
        )} */}
      </form>
    </Modal>
      <CustomTable dataSource={initialLectureData} columns={columns} />
      <AddLecture getFunction={getAllMyVediosFor} openModal={openModal} setOpenModal={setOpenModal} />
      <EditLecture openModal={editModal} setOpenModal={setEditModal} getFunction={getAllMyVediosFor} setRowData={setRowData} data={rowData} />
      <DeleteLecture
      getFunction={getAllMyVediosFor}
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
      />
      <ShowHideLectures
        openModal={openShowHideModal}
        setOpenModal={setOpenShowHideModal}
      />
    </div>
  );
}

export default NotesLectures;
