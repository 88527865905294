import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  days,
  deleteIcon,
  editIcon
} from "../../../assets/svgIcons";
import AddLecture from "../../../components/Lectures/add";
import DeleteLecture from "../../../components/Lectures/delete";
import EditLecture from "../../../components/Lectures/edit";
import ShowHideLectures from "../../../components/Lectures/showHide";
import CustomTable from "../../../components/table";
import { secondUrl } from "../../../utils/baseUrl";
import "./style.css";
import Toast from "../../../components/toast";
import Modal from "../../../components/modal";
import axios from "axios";
import { FaTrash } from "react-icons/fa";

function Lectures() {
  const userPermissions = localStorage.getItem("moreenglishlogin") ? JSON.parse(localStorage.getItem("moreenglishlogin"))?.permissions : null;
  const [spliitedUserPermission , setSplittedUserPermissions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [toast , setToast] = useState()
  const [rowData , setRowData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal , setOpenEditModal] = useState(false);
  const [openShowHideModal, setOpenShowHideModal] = useState(false);
  const [initialLectureData, setInitialLectureData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [img , setImg] = useState(null);
  const [imgUrl, setImgUrl] = useState("");

  const columns = [
    {
      key: "lec_title",
      title: "Lecture Name",
      dataIndex: "lec_title",
      search: true,
    },
    {
      key: "lec_cover_link",
      title: "Image",
      dataIndex: "lec_cover_link",
      render: (row) => {
        console.log(row);
        return (
          <img
            src={row}
            style={{ width: "100px" }}
            alt=""
            className=""
          />
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <div className="actions-btns">
          
         {spliitedUserPermission?.includes("13") &&  
         <div
            className="delete-btn c-pointer text-danger"
            onClick={() => setOpenDeleteModal(row)}
          >
            <div className="tooltip">Delete</div>
            {deleteIcon}
          </div>
          }

<div
            className="delete-btn c-pointer text-success"
            onClick={() => {
              setRowData(row)
              setOpenEditModal(true)
            }}
          >
            <div className="tooltip">Edit</div>
            {editIcon}
          </div>
          
          {spliitedUserPermission?.includes("40") && <div
            className="open-btn c-pointer text-primary"
            onClick={() => navigate(`${row?.lec_id}/videos`)}
          >
            <div className="tooltip">Videos</div>
            {days}
          </div>}
        </div>
      ),
    },
  ];
  const { subject, level_id } = useParams();
  const getAllMyVediosFor = async () => {
    setIsPageLoading(true);

    let data_to_send = {
      level_id:level_id
    };

    try {
      const response = await fetch("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/select_lectures_by_level.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data_to_send),
      });

      const resData = await response.json();
      console.log(resData);

      if (Array.isArray(resData) && resData.length !== 0) {
        const allData = resData.map((item) => ({
          ...item,
          deleteLoading: false,
          editLoading: false, 
        }));
console.log(allData)
        setInitialLectureData(allData);
      } else {
        setInitialLectureData([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsPageLoading(false);
    }
  };

  // function handleEditLecture(e) {
  //   e.preventDefault();

  //   if(img) {
  //     const formData = new FormData();
  //     formData.append("image", img)

  //     axios.post("" , formData)
  //     .then(res =>  {
  //       if(res.data.status = "success") {
  //         const data_to_send = {
  //           ...rowData , 
  //           lec_cover_link : res?.data
  //         }

  //         axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/edit_lec.php" , data_to_send)
  //         .then(editRes => {
  //           if(editRes?.data?.status == "success") {
  //              setToast({type:"success",message:res?.data?.message})
  //              getAllMyVediosFor();
  //              setOpenEditModal(false)
  //           }else {
  //             setToast({type:"error",message:res?.data?.message || "There's an error"})
  //           }
  //         }).catch(e => console.log(e))
  //         .finally(() => setOpenEditModal(false))
  //       }else {
         
  //       }
  //     })
  //   }
  // }

  function handleEditLecture() {

    const data_to_send = {
      ...rowData
    }
    axios.post("https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/edit_lec.php",data_to_send)
    .then(res => {
      console.log(res)
      if(res?.data == "success") {
         alert(res?.data);
         getAllMyVediosFor();
         setOpenEditModal(false);
      }else {
         alert(res?.data || "There's An Error")
      }
    }).catch(e => console.log(e))
    .finally(() => setOpenEditModal(false))
  }

  useEffect(() => {
    getAllMyVediosFor();
  }, []); 

  useEffect(() =>{
    const spliitedPermissions = userPermissions.split("//CAMP//");
    setSplittedUserPermissions(spliitedPermissions);
  } , [userPermissions ])
  
  const removeImage = () => {
    setRowData({ ...rowData, lec_cover_link: null });
  };
  return (
    <div className="lectures">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Lectures</h1>
        {spliitedUserPermission?.includes("12") && <button
          className="btn btn-success"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Add Lecture
        </button>}
      </div>

      <Modal
      close={setOpenEditModal}
      footer={false}
      title={"Edit Lecture"}
      visible={openEditModal}
    >
      <div  className="animated-form">
        <div className="form-group">
          <label htmlFor="lectureName" className="form-label">
            Lecture Name
          </label>
          <input
          value={rowData.lec_title}
            type="text"
            id="lectureName"
            placeholder="Enter Lecture Name"
            onChange={(e) =>
              setRowData({ ...rowData, lec_title: e.target.value })
            }
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lecturenumber" className="form-label">
            Lecture Arrangement
          </label>
          <input
            type="text"
            id="lecturenumber"
            placeholder="Enter Lecture Arrangement"
            value={rowData?.lec_arrangement}
            onChange={(e) =>
              setRowData({
                ...rowData,
                lec_arrangement: e.target.value,
              })
            }
            className="form-input"
          />
        </div>{" "}
        <div className="form-group">
          <label htmlFor="lecPrice" className="form-label">
            Lecture Price
          </label>
          <input
            type="text"
            id="v"
            placeholder="Enter Lecture Price"
            value={rowData?.lecture_price}
            onChange={(e) =>
              setRowData({
                ...rowData,
                lecture_price: e.target.value,
              })
            }
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lectureDescription" className="form-label">
            Description
          </label>
          <textarea
          value={rowData?.lec_descriprion}
            id="lectureDescription"
            placeholder="Enter Lecture Description"
            onChange={(e) =>
              setRowData({ ...rowData, lec_descriprion: e.target.value })
            }
            className="form-input"
            rows="4"
          />
        </div>
        <div className="form-footer">
          
            <button  className="btn btn-success" onClick={()=>{
              // addNew()
              handleEditLecture()
            }}>
              Save
            </button>
          
        </div>
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(false)}
          />
        )}
      </div>
    </Modal>

      <CustomTable dataSource={initialLectureData} columns={columns} />
      <AddLecture getFunction={getAllMyVediosFor} openModal={openModal} setOpenModal={setOpenModal} />
      {/* <EditLecture openModal={openEditModal} setOpenModal={setOpenEditModal} /> */}
      <DeleteLecture
      getFunction={getAllMyVediosFor}
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
      />
      <ShowHideLectures
        openModal={openShowHideModal}
        setOpenModal={setOpenShowHideModal}
      />
    </div>
  );
}

export default Lectures;
